import { Fancybox } from "@fancyapps/ui";

import "./reviewDetailed.css";
import "@/platform/components/review-widget/reviewScore.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]");

export interface ReviewImage {
  fullUrl: string;
  id: number;
  lightboxSizeUrl: string;
}

export interface Product {
  slug: string;
}
export interface Customer {
  slug: string;
}
